<template>
    <div class="card">
        <!--begin::Header-->
        <div class="card-header border-0">
            <p class="st-dark-heading mb-5">
                {{ $t('PROFILE.SECTIONS.RESPONSIBILITIES.HEADER') }}
            </p>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
          <div class="card-body pt-0">
            <div class="row">
                <div class="col-lg-12">
                    <ul class="col-stretch">
                        <li class="col-md-4 px-0" v-for="(responsibility, index) in details" :key="index">
                            {{ responsibility }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!--end: Card Body-->
    </div>
</template>

<script>
export default {
    name: 'AccountDetails',
    props: {
        details: {
            type: Array,
            required: true,
        },
    },
};
</script>
